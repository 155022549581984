// Menu

@import "../common/breakpoints";

// Product Held
.co-floating {
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-md {
        left: 300px;
        right: 300px;
    }
    .countdown {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #333;
        border-radius: 999rem;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 32px 0px;
        padding: 11px 26px 11px 22px;
        @include media-breakpoint-md {
            padding-top: 13px;
            padding-bottom: 13px;
        }
        color: #fff;
        cursor: pointer;
        line-height: 0.955;
        svg {
            height: 26px;
            width: 26px;
            margin: 0px 4px 0px 0px;
            fill: white;
        }
        .held {
            @include media-breakpoint-md {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .title{
                margin-bottom: 3px;
                @include media-breakpoint-md {
                    margin-bottom: 0;
                    margin-right: 3px;
                    margin-left: 10px;
                    font-size: 14px;
                }
            }
            .timer {
                span {
                    white-space: nowrap;
                    font-size: 14px;
                }
            }
        }
    }
}

// s-logos
.s-logos {
    img {
        &.logo {
            &.size-50 { // XX Large
                max-height: 60px;
            }
        }
    }
}

// site-wide-notice
.site-wide-notice{
    background-color: #fa6160E6; // 90% opacity
    // background-color: #fa61601A; // 10% opacity
    // background-color: #fa6160; // 0% opacity
    color: white;
    padding: 4px;
    min-height: 45px;
    display: flex;
    align-items: center;
    p{
        line-height: 1.25;
        text-transform:capitalize;
        @include media-breakpoint-md {
            font-size: 17px;
        }
    }
    .btn{
        margin: 0;
        border: 0;
        padding: 9px 16px 8px 16px;
        height: auto;
        line-height: 1;
    }
}