// Block 41
@import "../../common/breakpoints";

section {
  &.block-41 {
    //border: 1px solid red;

    @include media-breakpoint-md-down {
      // applies the following rules only
      // if the section is first section of the page
      // if the block is 41
      // if it is parallax
      // if the screens is below 767.98px
      &.first-section {
        &.parallax {
          >.background-image-holder {
            min-height: 120% !important;
            top: -14% !important;
          }
        }
      }
    }

    @include media-breakpoint-down-height-md {
      .pos-vertical-center {
        top: 0;
        transform: none;
        -webkit-transform: none;
      }

      &.height-100 {
        height: auto
      }
    }

    @media (max-width: 1024px) {
      .yt-wrapper{
        position: unset;
      }
    }

    @include media-comb-one {
      &.height-100 {
        height: 100vh;
      }
    }

    @include media-comb-two {
      &.first-section {
        &.height-half {
          &.parallax {
            >.background-image-holder {
              min-height: 60vh;
            }
          }
        }
      }
    }

    &.block-align-left {

      .img-container {
        display: inline-flex;
      }

      .c-btn-group {
        .btn {
          &.btn--secondary {
            margin-left: 0 !important;
          }

          @include media-breakpoint-md-down {
            + {
              .btn {
                margin-left: 0 !important;
              }
            }
          }
        }
      }

    }

    &.block-align-right {
      .img-container {
        display: inline-flex;
      }
    }

    img,svg{
      &.banner_logo{
        max-height: 300px;
        &.s-large{
          max-height: 400px;
        }
        &.s-small{
          max-height: 200px;
        }
      }
    }

    &[data-gradient-bg] {
      // show Gradient BG on top of the YoutubE Video
      > canvas {
        + .yt-container {
          opacity: 0.2;
        }
      }
    }

    &[data-overlay] {
      // show Data Overlay on top of the YoutubE Video
      .yt-container {
        z-index: 0;
      }
    }
  }
}
