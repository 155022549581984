//
// react-datetime
//

.react-datetime {
  .rdtPicker {
    padding: 0 16px;

    .rdtDays {
      table {
        max-width: 100%;

        th,
        td {
          padding: 10.45px 0;
          width: calc(100%/7);
        }

        th {

          &.rdtPrev,
          &.rdtNext {
            text-align: center;

            span {
              float: none;
              font-size: 50px;
              line-height: 1;
              color: rgba($color: #252525, $alpha: 0.6);
              cursor: pointer;

              &:hover {
                color: rgba($color: #252525, $alpha: 0.8);
              }
            }
          }
        }

        td {
          font-size: 14px;
          cursor: pointer;
          div{
            height: 26px;
            width: 26px;
            border-radius: 14px;
            text-align: center;
            margin: 0 auto;
            &:hover{
              background-color: rgba($color: #888888, $alpha: 0.1);
            }
          }
          &.rdtActive{
            div{
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

// 700 x 385