//
// Common classes
//

body.disable-scroll {
  overflow: hidden;
}

// Temp fix to overwrite boipapaymentgateway css
html{
  overflow-y: auto !important;
  height: auto !important;
}

// link-share
.link-share{
  .r-box{
    border-radius: 50%;
    padding: 3px;
    font-size: 16px;
    line-height: 1;
    margin-right: 6px;
    color: #fff;
  }
}

// Modal
.modal-regular .modal-content .boxed {
  animation:300ms ease 0s 1 normal none running showSweetAlert;
}

@keyframes showSweetAlert {  
  0% {    
    transform: scale(0.7);    -webkit-transform: scale(0.7);  
  }  
  45% {    
    transform: scale(1.05);    -webkit-transform: scale(1.05);  
  }  
  80% {    
    transform: scale(0.95);    -webkit-tranform: scale(0.95);  
  }  
  100% {    
    transform: scale(1);    -webkit-transform: scale(1);  
  }
}

// Font sizes
@media (min-width: 767px){
  .fs-md-30{
    font-size: 30px;
  }
}

.lh-1{
  line-height: 1;
}

// overflow
.of-visible {
  overflow: visible !important; 
}

// Lazy loading CSS background images
.lazy-background{
  &:not(.lazy-visible) {
      background-image: none !important
  }
} 

// ellipsis
.ellipsis-1,
.ellipsis-2,
.ellipsis-3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  @supports (-webkit-line-clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.ellipsis-2 {
  @supports (-webkit-line-clamp: 2) {
    -webkit-line-clamp: 2;
  }
}

.ellipsis-3 {
  @supports (-webkit-line-clamp: 3) {
    -webkit-line-clamp: 3;
  }
}

// main-template || Template Page
.main-template{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .main-template-content{
    flex: 1 1 auto;
  }
}

// social list
.social-list {
  .svg-socicon {
    display: flex;
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 0;
    top: 1px;
  }
}
