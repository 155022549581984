//
// block 58
//

.block-58 {

  //items
  .item {
    margin: 16px 0;

    &.colums-5 {
      @include media-breakpoint-lg {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }

    a {
      position: relative;
      z-index: 0;
      display: block;
      overflow: hidden;

      img {
        // Image size: 300px width × 450px height, or any size is fine as long as respects the aspect ratio 2:3
        transition: transform 0.8s;
        transform-style: preserve-3d;
        margin: 0;
        width: 100%;
        aspect-ratio: 2/3;
      }

      span {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 86%;
        background-color: #0171cd;
        opacity: 0.9;
        padding: 16px 16px 16px 16px;
        font-size: 18px;
        color: #fff;
        line-height: 30px;
        font-weight: 700;
        line-height: 1.71em;
        letter-spacing: 0.13px;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        text-align: center;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        i{
          &.name{
            font-style: normal;
          }
        }

        small {
          .m_t {
            margin-top: 16px;
          }
        }
      }

    }

    &:hover {
      img {
        transform: rotateY(360deg);
      }

      span {
        top: 0%;
        align-items: center;
        justify-content: center;
        padding: 16px;
      }
    }
  }

  .row-cols-2 {
    .item {
      &.col {
        flex: 0 0 50%;
        max-width: 50%;

        a {
          span {
            padding-top: 2px;

            @include media-breakpoint-md {
              padding-top: 16px;
            }
          }

          &:not(:hover) {
            span {
              @include media-breakpoint-md {
                top: 88.6%;
              }

              @include media-breakpoint-lg {
                top: 93%;
              }
            }
          }
        }
      }
    }
  }

  .row-cols-3 {
    .item {
      &.col {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;

        a {
          span {
            padding-top: 2px;
          }

          &:not(:hover) {
            span {
              top: 77%;

              @include media-breakpoint-md {
                top: 88.6%;
              }

              @include media-breakpoint-lg {
                top: 93%;
              }
            }
          }
        }

        @include media-breakpoint-md-down {
          a{
            margin: -12px -10px;
            span{
              line-height: 1.5;
              font-size: 10px;
              padding: 1px;
            }
            &:not(:hover) {
              span {
                top: 91%;
              }
            }
          }
        }

      }
    }
  }

  .row-cols-4 {
    .item {
      &.col {
        flex: 0 0 25%;
        max-width: 25%;

        a {
          span {
            padding-top: 0;

            @include media-breakpoint-lg {
              padding-top: 16px;
            }
          }

          &:not(:hover) {
            span {
              top: 71%;

              @include media-breakpoint-md {
                top: 86.6%;
              }

              @include media-breakpoint-lg {
                top: 85.5%;
              }
            }
          }
        }
        @include media-breakpoint-md-down {
          a{
            margin: -12px -10px;
            span{
              line-height: 1.25;
              font-size: 10px;
              padding: 2px;
              i{
                &.name{
                  font-size: 8px;
                }
              }
            }
            &:not(:hover) {
              span {
                top: 89%;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-md {

    .row-cols-md-2 {
      .item {
        &.col {
          flex: 0 0 50%;
          max-width: 50%;
  
          a {
            span {
              padding-top: 16px;
            }
  
            &:not(:hover) {
              span {
                top: 93%;
              }
            }
          }
        }
      }
    }
  
    .row-cols-md-3 {
      .item {
        &.col {
          flex: 0 0 33.3333333333%;
          max-width: 33.3333333333%;
  
          a {
            span {
              padding-top: 2px;
            }
  
            &:not(:hover) {
              span {
                top: 77%;
  
                @include media-breakpoint-md {
                  top: 88.6%;
                }
  
                @include media-breakpoint-lg {
                  top: 93%;
                }
              }
            }
          }
        }
      }
    }

    .row-cols-md-4 {
      .item {
        &.col {
          flex: 0 0 25%;
          max-width: 25%;

          a {
            span {
              padding-top: 16px;
            }

            &:not(:hover) {
              span {
                top: 85.5%;
              }
            }
          }
        }
      }
    }

    .row-cols-md-5 {
      .item {
        &.col {
          flex: 0 0 20%;
          max-width: 20%;

          a {
            span {
              padding-top: 6px;
            }

            &:not(:hover) {
              span {
                top: 86%;
              }
            }
          }
        }
      }
    }
  }

}