//
// Comopnents
// Cookie Banner

$md-b: 767px;
$md: 768px;
$lg: 992px;

.cookie-banner {
  h5,
  p {
    margin-bottom: 16px;
  }

  .buttons {
    .r1 {
      @media (min-width: $lg) {
        padding: 16px 0;
      }
    }
  }

  .banner {
    position: fixed;
    z-index: 100;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    padding: 30px;
    max-width: 432px;
    &.position-bottom-right {
      left: 20px;
      right: 20px;
      bottom: 20px;
      @media (min-width: $md) {
        left: auto;
      }
    }
    &.position-bottom-left {
      left: 20px;
      right: 20px;
      bottom: 20px;
      @media (min-width: $md) {
        right: auto;
      }
    }
    &.position-bottom,
    &.position-top {
      left: 20px;
      right: 20px;
      max-width: none;
      @media (min-width: $lg) {
        display: flex;
        align-items: center;
        .buttons {
          min-width: 410px;
          padding: 0 0 0 40px;
        }
      }
    }
    &.position-bottom {
      bottom: 20px;
    }
    &.position-top {
      top: 20px;
    }
  }

  .privacy-settings {
    //margin: 0 -26px;
    @media (min-width: $md) {
      //margin: 0;
    }
    .link-privacy {
      text-align: right;
      margin-top: -46px;
      @media (min-width: $md) {
        margin-top: -56px;
      }
    }
    .tabs {
      padding: 16px 0 0 0;
      border: 0;
      .lists {
        button {
          cursor: pointer;
          height: auto;
          padding: 13px 13px;
          @media (min-width: $md) {
            padding: 13px 26px;
          }
          &:hover,
          &:active,
          &:focus {
            background-color: transparent;
          }
        }
      }
      .panels {
        padding: 32px 16px 0 16px;
        .panel {
          .title {
            padding-bottom: 6px;
            &.w-p-b-color {
              border-bottom: 1px solid #ddd;
            }
          }
          .types {
            padding-top: 16px;
            .type {
              padding-bottom: 16px;
            }
          }
          &.settings {
            .types {
              .type {
                .r1 {
                  position: relative;
                  z-index: 0;
                  a {
                    position: absolute;
                    right: 9px;
                    top: 0;
                    display: inline-block;
                    padding: 0 22px 0 0;
                    span {
                      color: transparent;
                      width: 0;
                      height: 0;
                      -webkit-transform: translate(-50%, -50%) rotate(0deg);
                      transform: translate(-50%, -50%) rotate(0deg);
                      border-left: 5.04px solid transparent;
                      border-right: 5.04px solid transparent;
                      border-top: 7px solid #ddd;
                      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                      text-decoration: none;
                      position: absolute;
                      right: 0;
                      top: 13px;
                    }
                  }
                }
                &.show-more {
                  .r1 {
                    a {
                      span {
                        transform: translate(-50%, -50%) rotate(180deg);
                      }
                    }
                  }
                }
                .toggle {
                  @media (max-width: $md-b) {
                    margin: 0;
                  }
                  h4 {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: inherit;
                    color: inherit;
                  }
                }
              }
            }
          }
          &.cookies {
            .list {
              .sub-title {
                font-weight: 600;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .buttons {
      .r2 {
        @media (max-width: $md-b) {
          padding: 0 15px;
        }
      }
    }
  }
}
